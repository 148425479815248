import React, { FC } from 'react'

import { PrismicRawStructuredText } from '../../types'
import { ImagePropsFragment, Maybe } from '../../../graphql-types'
import { SoundSectionData } from '../../types'
import { colors, mq, styled, widthToFill } from '../../styles'
import Title from '../Title'
import { withLineBreaks } from '../../lib/RenderUtils'
import FluidImage from '../base/FluidImage'
import Section from '../Section'
import P from '../base/P'
import BaseSubtitle from '../Subtitle'

const SoundSection: FC<{
  section: SoundSectionData
}> = ({ section }) => {
  return (
    <Container>
      <Title>{withLineBreaks(section.section_title?.text || '')}</Title>
      <Subtitle>{section.subtitle?.text}</Subtitle>
      <ImageContainer>
        <Image image={section.image} />
      </ImageContainer>
      <TextContainer>{section.text?.text}</TextContainer>
      <ItemContainer>
        {section.items?.map((item, i) => {
          return (
            <TextBlock
              key={i}
              title={item?.title?.text}
              image={item?.image}
              content={item?.content?.raw}
            />
          )
        })}
      </ItemContainer>
      <QuoteContainer>
        {section.quotes?.map((quote, i) => {
          return (
            <Quote key={i}>
              <QuoteText>{quote?.quote?.text}</QuoteText>
              <QuoteAuthor>{quote?.author}</QuoteAuthor>
            </Quote>
          )
        })}
      </QuoteContainer>
    </Container>
  )
}

const TextBlock: FC<{
  title?: Maybe<string>
  content?: Maybe<PrismicRawStructuredText[]>
  image?: Maybe<{ alt?: Maybe<string>; fluid?: Maybe<ImagePropsFragment> }>
}> = ({ title, content, image }) => {
  return (
    <TextBlockContainer>
      {image && (
        <TextBlockImageContainer>
          <TextBlockImage image={image} aspect={1} transparent />
        </TextBlockImageContainer>
      )}

      {title && <TextBlockTitle>{title}</TextBlockTitle>}
      {content && (
        <TextBlockText>
          {content.map((c, i) => {
            return <P key={i}>{c.text}</P>
          })}
        </TextBlockText>
      )}
    </TextBlockContainer>
  )
}

const Container = styled(Section)({})

const Subtitle = styled(BaseSubtitle)(
  {},
  mq({
    top: [-7, 0],
    marginTop: [0, '.2em'],
    marginBottom: ['1em', '1em'],
  }),
)

const ImageContainer = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mq({
    marginTop: ['4em', '1em'],
    width: [460, '100%'],
  }),
)

const Image = styled(FluidImage)(
  {
    width: '100%',
    margin: '0 auto',
  },
  mq({}),
)

const TextContainer = styled(P)(
  {
    maxWidth: 800,
    margin: '1.5em auto 6em',
    color: '#b77e44',
  },
  mq({
    marginBottom: ['6em', '3em'],
  }),
)

const ItemContainer = styled('div')(
  {
    display: 'flex',
  },
  widthToFill(1000),
  mq({
    flexDirection: ['row', 'column'],
  }),
)

const TextBlockContainer = styled('div')(
  mq({
    textAlign: 'center',
    flex: 1,
    margin: '0 2em',
    marginBottom: [0, '3em'],
    ':last-child': {
      marginBottom: [0, 0],
    },
  }),
)

const TextBlockImageContainer = styled('div')(
  {
    padding: '0 1em',
  },
  mq({
    padding: ['0 1em', '0 4em'],
  }),
)

const TextBlockImage = styled(FluidImage)(
  {
    margin: '0 auto',
    marginBottom: '1.5em',
    maxWidth: '100%',
  },
  mq({
    marginBottom: ['1.5em', 0],
  }),
)

const TextBlockTitle = styled('div')({
  fontSize: '1.5em',
  marginBottom: '.5em',
  lineHeight: 1,
})

const TextBlockText = styled('div')({})

const QuoteContainer = styled('div')(
  mq({
    display: 'flex',
    flexDirection: ['row', 'column'],
    maxWidth: 1000,
    margin: ['6em auto 0', '3em auto 0'],
    color: colors.goldenBrown,
  }),
)

const Quote = styled('div')(
  mq({
    flex: 1,
    padding: ['1em 1em 0', '1em'],
    margin: ['0 auto', '0 auto 1em'],
  }),
)

const QuoteText = styled('div')({
  fontSize: '1.5em',
  textAlign: 'center',
})

const QuoteAuthor = styled('div')({ marginTop: '.5em', textAlign: 'center' })

export default SoundSection
