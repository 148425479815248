import styled from '@emotion/styled'
import React, { ComponentProps, FC, useCallback } from 'react'
import { recordShopLinkClick } from '../lib/Analytics'
import useShopUrl from '../lib/useShopUrl'
import { colors, mq } from '../styles'
import { LinkButton } from './Button'

type ShopButtonProps = Omit<ComponentProps<typeof LinkButton>, 'href'> & {
  location: string
  color?: 'light' | 'dark'
}

const ShopButton: FC<ShopButtonProps> = (props) => {
  const shopUrl = useShopUrl()

  const handleClick = useCallback(() => {
    recordShopLinkClick(props.location)
  }, [props.location])

  return (
    <BaseButton
      id={`shop-button-${props.location}`}
      {...props}
      onMouseDown={handleClick}
      href={shopUrl}
    />
  )
}

const BaseButton = styled(LinkButton)<{ color?: 'light' | 'dark' }>(
  ({ color }) => ({
    backgroundColor: color === 'dark' ? colors.darkBrown : colors.lightBrown,
    color: color === 'dark' ? colors.lightBrown : colors.darkBrown,
    width: '100%',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  mq({
    padding: ['1px 25px 4px', '8px 20px', '8px 20px', '7px 15px'],
  }),
)

export default ShopButton
