import React, { FC } from 'react'

import { HeroSpeakerSectionData } from '../../types'
import Title from '../../components/Title'
import { mq, styled } from '../../styles'
import Section from '../Section'
import FluidImage from '../base/FluidImage'
import Caption from '../Caption'
import BaseSubtitle from '../Subtitle'
import ShopLink from '../ShopLink'

const HeroSpeakerSection: FC<{
  section: HeroSpeakerSectionData
}> = ({ section }) => {
  return (
    <Container>
      <SectionTitle>{section.section_title?.text}</SectionTitle>
      <Subtitle>{section.section_subtitle?.text}</Subtitle>
      <ImageLink location={'hero-image'}>
        <Image image={section.image} />
      </ImageLink>
      <Caption>{section.image?.alt}</Caption>
    </Container>
  )
}

const Container = styled(Section)(
  {
    backgroundSize: 'auto 60%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom center',
    flexDirection: 'column',
  },
  mq({
    minHeight: [850, 450],
    backgroundSize: ['auto 65%', '100% auto'],
    paddingBottom: [0, 0],
  }),
)

const SectionTitle = styled(Title)({
  maxWidth: 1100,
  top: 0,
})

const Subtitle = styled(BaseSubtitle)(
  {
    fontSize: '2.5em',
    marginTop: '.5em',
    marginBottom: '0',
  },
  mq({
    fontSize: ['2.5em', '1.5em'],
  }),
)

const ImageLink = styled(ShopLink)(
  mq({
    display: 'block',
    paddingTop: ['2em', '.75em'],
    width: [1000, '100%'],
  }),
)

const Image = styled(FluidImage)({
  maxWidth: '100%',
})

export default HeroSpeakerSection
