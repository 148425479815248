import { useStaticQuery, graphql } from 'gatsby'

const useShopUrl = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicSplash {
        data {
          product_page_url
        }
      }
    }
  `)
  return data.prismicSplash.data.product_page_url
}

export default useShopUrl
