import React, { FC } from 'react'

import { PreorderSectionData } from '../../types'
import { colors, fonts, mq, styled, widthToFill } from '../../styles'
import Section from '../Section'
import P from '../base/P'
import ShopButton from '../ShopButton'

const PreorderSection: FC<{
  section: PreorderSectionData
}> = ({ section }) => {
  return (
    <>
      <TitleContainer>
        <SectionTitle>{section.section_title?.text}</SectionTitle>

        <BatchesOverview> {section.section_subtitle?.text}</BatchesOverview>

        <BatchesContainer>
          {section.batches?.map((b, i) => {
            return (
              <Batch key={i} soldOut={!!b?.sold_out}>
                <BatchContent>
                  <BatchHeader>
                    <BatchName>{b?.name?.text}</BatchName>
                  </BatchHeader>
                  <BatchInfo>
                    {b?.sold_out ? (
                      <>
                        <SoldOutMessage key={`info-${i}`}>
                          All Units Sold
                        </SoldOutMessage>
                      </>
                    ) : (
                      <>
                        <div>
                          <AvailableText>NOW AVAILABLE</AvailableText>
                          <P>Delivery</P>
                          <BatchDeliveryInfo key={`info-${i}`}>
                            {b?.delivery_info}
                          </BatchDeliveryInfo>
                        </div>
                        <div>
                          <P>Price</P>
                          <BatchPriceInfo key={`info-${i}`}>
                            {b?.price}
                          </BatchPriceInfo>
                        </div>
                      </>
                    )}
                  </BatchInfo>
                </BatchContent>
              </Batch>
            )
          })}
        </BatchesContainer>
        <OrderButtonContainer>
          <OrderButton location="main-preorder" color="light">
            BECOME A MEMBER
          </OrderButton>
        </OrderButtonContainer>
        <P style={{ marginTop: '1em' }}>
          Price does not include Oda membership
        </P>
      </TitleContainer>
    </>
  )
}

const Container = styled(Section)({
  backgroundColor: colors.darkBrown,
  color: colors.lightBrown,
})

const TitleContainer = styled(Container)({})

const SectionTitle = styled('div')(
  {
    fontFamily: fonts.garamond.condensed,
    fontSize: '15em',
    letterSpacing: -4,
    lineHeight: 0.9,
  },
  mq({
    marginTop: [0, '.2em'],
    fontSize: ['15em', '10em'],
    letterSpacing: [-4, -2],
    lineHeight: [0.9, 0.75],
    textAlign: ['center'],
  }),
)

const OrderButtonContainer = styled('div')(
  {
    width: '100%',
  },

  mq({
    maxWidth: [800],
    margin: ['0 auto'],
  }),
)

const OrderButton = styled(ShopButton)(
  {
    width: '100%',
    fontSize: '5.5vw',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    paddingBottom: '.1em',
    textAlign: 'center',
  },
  mq({
    fontSize: ['6em', '2.5em'],
    borderRadius: [110, '12.5vw'],
    height: [150, 100],
    letterSpacing: [1, 0],
    paddingBottom: ['.1em', '.3em'],
  }),
)

const InfoHeader = styled('div')({
  fontSize: '1.3em',
})

const BatchesOverview = styled(P)(
  mq({
    marginBottom: ['4em', '2em'],
    marginTop: ['-2em', 0],
    maxWidth: 550,
    marginLeft: 'auto',
    marginRight: 'auto',
  }),
)

const BatchesContainer = styled('div')(
  {
    display: 'flex',
    flex: 1,
  },
  mq({
    alignItems: ['stretch', 'center'],
    flexDirection: ['row', 'column'],
    paddingTop: [0, '2em'],
    marginBottom: ['3em'],
    width: [1000, '100%'],
  }),
)

const Batch = styled('div')<{ soldOut: boolean }>({}, ({ soldOut }) =>
  mq({
    flex: 1,
    width: ['33%', '100%'],
    margin: ['0 1em', '.5em 0'],
    opacity: soldOut ? 0.5 : 1,
  }),
)

const BatchContent = styled('div')(
  mq({
    border: `1px solid ${colors.lightBrown}`,
    textAlign: 'center',
    borderRadius: '2em',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
)

const BatchHeader = styled('div')({
  padding: '1.5em',
  borderBottom: `1px solid ${colors.lightBrown}`,
})

const BatchInfo = styled('div')({
  padding: '1.5em',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
})

const AvailableText = styled('div')({
  fontSize: ['2.3em'],
  marginBottom: '1em',
  maxWidth: 200,
  lineHeight: 1,
  marginLeft: 'auto',
  marginRight: 'auto',
})

const BatchName = styled('div')(
  mq({
    fontFamily: fonts.garamond.condensed,
    fontSize: ['2.3em'],
    lineHeight: 1,
    height: '.7em',
  }),
)

const SoldOutMessage = styled('div')(
  mq({
    fontSize: '2.5em',
    lineHeight: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    height: '100%',
  }),
)

const BatchDeliveryInfo = styled('div')({
  fontSize: '2.5em',
  lineHeight: '1em',
  margin: '0 auto',
  marginBottom: '1em',
  maxWidth: 150,
})

const BatchPriceInfo = styled('div')({
  fontSize: '2.5em',
  lineHeight: '1em',
})

export default PreorderSection
