import React, { FC } from 'react'

// import { OverviewSectionData } from '../../types'
import { fonts, mq, styled, widthToFill } from '../../styles'
import FluidImage from '../base/FluidImage'
import P from '../base/P'
import Title from '../Title'
import Logo from '../../images/logo.png'
import ShopButton from '../ShopButton'

const AboutOdaSection: FC = () => {
  return (
    <Container>
      <Header>
        <HeaderTitle>
          <HeaderTextPart>Listen. No, Really Listen.</HeaderTextPart>
        </HeaderTitle>
        <HeaderDescription>
          Intimate music performances.<br />
          Immersive sound events. Unexpected journeys.<br />
          All broadcast directly to you live with uncanny presence.<br />
          Rediscover wonder in the world through sound.
        </HeaderDescription>
        <ItemImage
          image={{
            fluid: {
              src: '/overview.png',
              srcSet: '/overview.png',
              aspectRatio: 1.5,
            },
          }}></ItemImage>
        {/* <HeaderTitle>
          <HeaderTextPart>Oda is Live.</HeaderTextPart>
        </HeaderTitle> */}
      </Header>

      <HeaderDescription>
        Oda is a membership to live listening.
        </HeaderDescription>

      <JoinUsContainer>
        <OrderButton color="dark" location="program-hero">
          BECOME A MEMBER
        </OrderButton>
      </JoinUsContainer>
    </Container>
  )
}

const Container = styled('div')(
  {
    flexDirection: 'column',
  },
  mq({
    paddingBottom: ['8rem', '5rem'],
  }),
)

const Header = styled('div')(
  mq({
    verticalAlign: 'middle',
    marginTop: ['0rem'],
  }),
)

const HeaderLogo = styled('img')(
  mq({
    width: [112, 80],
    height: [112, 80],
    margin: ['0 15px', '0px 0 20px'],
    display: 'inline-block',
    verticalAlign: 'middle',
  }),
)

const HeaderTitle = styled(Title)(
  mq({
    paddingTop: ['1.5em', 0],
    textAlign: 'center',
    margin: '0 auto',
    verticalAlign: 'middle',
    fontFamily: fonts.garamond.condensed,
    display: 'flex',
    flexDirection: ['row', 'column'],
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 0.9,
    maxWidth: ['auto', 250],
  }),
)

const HeaderTextPart = styled('div')({})

const HeaderDescription = styled('div')(
  mq({
    fontSize: [48, 36],
    fontFamily: fonts.garamond.condensed,
    maxWidth: 1100,
    lineHeight: [1, 1],
    textAlign: 'center',
    margin: '0 auto',
    marginTop: ['2em', '1em'],
  }),
)

const ItemImage = styled(FluidImage)(
  {},
  mq({
    maxWidth: [1000, '100%'],
    margin: ['10em auto 0em', '4em auto 0em'],
  }),
)

const JoinUsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 20px',
})

const OrderButton = styled(ShopButton)(
  {
    textTransform: 'none',
  },
  mq({
    margin: '0 auto',
    width: ['auto', '100%'],
    marginTop: ['1rem', '2rem'],
    fontSize: ['3em', '2em'],
    padding: ['2px 100px 8px'],
  }),
)

export default AboutOdaSection
