import React, { FC, useEffect, useMemo, useState } from 'react'

import { ArtistNamesSectionData } from '../../types'
import { colors, fonts, mq, styled, widthToFill } from '../../styles'
import H1 from '../base/H1'
import Section from '../Section'
import BaseShopButton from '../ShopButton'
import VideoPlayer from '../VideoPlayer'
import Caption from '../Caption'

const ArtistNamesSection: FC<{
  section: ArtistNamesSectionData
}> = ({ section }) => {
  return (
    <Container withoutTitle={true}>
      <SectionTitle>Featuring</SectionTitle>
      <Content>
        {renderNames(section.artist_names?.raw)}
        {section.sentence_end_text}
      </Content>
      <IntroVideo />
      <Caption>
        Oda connects you directly to live artist performances. <br />
        Like two cans and a string.
      </Caption>
      <ShopButton location="artist-names" color="dark">
        Become a Member
      </ShopButton>
    </Container>
  )
}

const IntroVideo = () => {
  const [windowWidth, setWindowWidth] = useState(0)
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const { id, aspect } = useMemo(() => {
    return windowWidth > 600
      ? { id: '070c274f0a910b832a02db168023338d', aspect: 2.4 }
      : { id: 'e66309ebe8cb806108b3b802100e0ed2', aspect: 1.5 }
  }, [windowWidth])

  return <Video key={id} id={id} controls aspect={aspect} />
}

interface RawText {
  type: string
  text: string
}

const renderNames = (names?: RawText[]) => {
  if (!names) return null

  const items = []
  for (let i = 0; i < names.length; ++i) {
    items.push(<Name key={`name-${i}`}>{names[i]?.text}</Name>)
    if (i + 1 !== names.length) {
      items.push(<Dot key={`dot-${i}`}>{' ● '}</Dot>)
    }
  }
  return items
}

const Container = styled(Section)(
  {
    backgroundColor: colors.mediumBrown,
    alignItems: 'center',
    textAlign: 'center',
    borderTop: 0,
  },
  mq({
    paddingTop: [0, 0],
  }),
)

const SectionTitle = styled('div')(
  mq({
    margin: '0 auto',
    fontFamily: fonts.monosten.light,
    color: colors.goldenBrown,
    maxWidth: ['auto', 250],
    fontSize: ['1em', '1em'],
    marginBottom: ['.25em', '.25em'],
    marginTop: '1.5em',
    lineHeight: ['1.4em', '1em'],
  }),
)

const Content = styled('div')(
  mq({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    fontSize: ['1.4em', '1.2em'],
    lineHeight: ['1.4em', '1.2em'],
  }),
  widthToFill(1000),
)

const Name = styled('div')({
  whiteSpace: 'nowrap',
})

const Dot = styled('div')({
  margin: '0 .25em',
})

const Video = styled(VideoPlayer)(
  mq({
    width: [1200, '100%'],
    maxWidth: '100%',
    margin: '0 auto',
    marginTop: '3em',
  }),
)

const ShopButton = styled(BaseShopButton)(
  mq({
    maxWidth: 500,
    margin: '1em auto 0',
    fontSize: ['3.5em', '2em'],
  }),
)

export default ArtistNamesSection
