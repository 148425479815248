import React, { FC } from 'react'

import { ArtistsSectionData } from '../../types'
import { fonts, fullWidth, mq, styled } from '../../styles'
import Title from '../Title'
import FluidImage from '../base/FluidImage'
import { withLineBreaks } from '../../lib/RenderUtils'
import Section from '../Section'
import P from '../base/P'
import { keyframes } from '@emotion/react'

const ArtistsSection: FC<{
  section: ArtistsSectionData
}> = ({ section }) => {
  return (
    <Container>
      <MainTitle>{section.section_title?.text}</MainTitle>
      <HeaderContainer>
        <HeaderImage image={section.image} transparent />
        <HeaderStar src="/star.svg" />
      </HeaderContainer>
      <ProgrammingContainer>
        <ProgrammingTitle>{section.programming_title.text}</ProgrammingTitle>
        <ProgrammingSubtitle>
          {section.programming_subtitle.text}
        </ProgrammingSubtitle>
        <ProgrammingOverview>
          {withLineBreaks(section.programming_overview?.text)}
        </ProgrammingOverview>
        <ProgrammingItems>
          {section.programming_items.slice(0, 3).map(renderProgrammingItem)}
        </ProgrammingItems>
        <ProgrammingItems>
          {section.programming_items.slice(3).map(renderProgrammingItem)}
        </ProgrammingItems>
      </ProgrammingContainer>

      <ArtistsInResidenceSection>
        <SecondarySectionTitleBlock>
          <SecondarySectionTitleBlockContent>
            <ArtistsInResidenceSectionTitle>
              {section.artists_in_residence_title?.text}
            </ArtistsInResidenceSectionTitle>
            <SecondarySectionSubtitle>
              <SecondarySectionSubtitleInner>
                {section.artists_in_residence_description?.text}
              </SecondarySectionSubtitleInner>
            </SecondarySectionSubtitle>
          </SecondarySectionTitleBlockContent>
        </SecondarySectionTitleBlock>
        {section.artists_in_residence.map((a, i) => {
          return (
            <ResidentArtist key={i}>
              <ResidentArtistImage image={a?.image1} aspect={1} />
              <ResidentArtistName>{a.name?.text}</ResidentArtistName>
              <ResidentArtistLocation>
                {a.location?.text}
              </ResidentArtistLocation>
            </ResidentArtist>
          )
        })}
      </ArtistsInResidenceSection>

      <WeekendArtistsSection>
        <SecondarySectionTitleBlock>
          <SecondarySectionTitleBlockContent>
            <WeekendArtistsSectionTitle>
              {section.weekend_artist_title?.text}
            </WeekendArtistsSectionTitle>
            <SecondarySectionSubtitle>
              <SecondarySectionSubtitleInner>
                {section.weekend_artist_subtitle?.text}
              </SecondarySectionSubtitleInner>
            </SecondarySectionSubtitle>
          </SecondarySectionTitleBlockContent>
        </SecondarySectionTitleBlock>
        {section.weekend_artists.map((a, i) => {
          return (
            <WeekendArtist key={i}>
              <WeekendArtistName>
                {withLineBreaks(a.name?.text)}
              </WeekendArtistName>
            </WeekendArtist>
          )
        })}
      </WeekendArtistsSection>
    </Container>
  )
}

const renderProgrammingItem = (
  blocks: {
    content: { raw: { type: string; text: string }[] }
  },
  i: number,
) => {
  return (
    <ProgrammingItem key={i}>
      {blocks?.content?.raw?.map((b, i) => {
        if (b.type === 'heading3') {
          return (
            <ProgrammingItemTitle key={i}>
              {withLineBreaks(b.text)}
            </ProgrammingItemTitle>
          )
        } else if (b.type === 'heading4') {
          return (
            <ProgrammingItemSubtitle key={i}>{b.text}</ProgrammingItemSubtitle>
          )
        } else if (b.type === 'paragraph') {
          return <ProgrammingItemText key={i}>{b.text}</ProgrammingItemText>
        }
      })}
    </ProgrammingItem>
  )
}

const Container = styled(Section)({
  alignItems: 'center',
})

const MainTitle = styled(Title)(
  {
    margin: '0 auto',
  },
  fullWidth,
)

const HeaderContainer = styled('div')(
  {
    position: 'relative',
    overflow: 'hidden',
    paddingTop: '2em',
  },
  mq({
    width: [1000, '100%'],
  }),
)

const HeaderImage = styled(FluidImage)({
  maxWidth: '100%',
})

const rotate = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}
`
const HeaderStar = styled('img')({
  position: 'absolute',
  left: '50%',
  width: '32%',
  marginLeft: '-16%',
  top: '69%',
  animation: `${rotate} 120s linear infinite`,
})

const BaseSection = styled('div')(
  {
    width: 1000,
    margin: '0 auto',
    borderRadius: 30,
    border: '1px solid black',
    textAlign: 'center',
    position: 'relative',
  },
  fullWidth,
  mq({
    marginTop: ['6em', '4em'],
  }),
)

const ProgrammingContainer = styled(BaseSection)(
  {
    padding: 30,
    paddingBottom: '2em',
  },
  mq({
    marginTop: [0, 0],
  }),
)

const ProgrammingTitle = styled('div')(
  {
    fontFamily: fonts.garamond.condensed,
    fontSize: '4em',
  },
  mq({
    fontSize: ['4em', '3.9em', '4em', '3.1em'],
    lineHeight: [1.2, 0.75],
    marginBottom: [0, '.25em'],
  }),
)

const ProgrammingSubtitle = styled('div')({
  fontSize: '1.5em',
  marginBottom: '1em',
})

const ProgrammingOverview = styled('div')({
  fontSize: '1.5em',
  fontFamily: fonts.garamond.condensed,
  marginBottom: '1em',
  maxWidth: 600,
  margin: '0 auto',
})

const ProgrammingItems = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
})

const ProgrammingItem = styled('div')(
  {
    marginTop: '2em',
  },
  mq({
    width: ['33%', '100%'],
  }),
)

const ProgrammingItemTitle = styled('div')({})

const ProgrammingItemSubtitle = styled('div')({
  fontSize: '14px',
})

const ProgrammingItemText = styled(P)({
  marginTop: '1em',
})

const SecondarySectionTitleBlock = styled('div')({}, (props) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 0,
}))

const SecondarySectionTitleBlockContent = styled('div')(
  {},
  (props) => ({
    position: 'relative',
    backgroundColor: props.theme.backgroundColor,
    padding: '0 2em',
  }),
  mq({
    margin: [0, '0 2em'],
    padding: ['0 2em', '0 .1em'],
  }),
)

const SecondarySectionTitle = styled('div')(
  {
    lineHeight: 1,
  },
  mq({
    fontSize: ['2.5em', '2em'],
  }),
)

const SecondarySectionSubtitle = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: 0,
})

const SecondarySectionSubtitleInner = styled(P)({
  maxWidth: 300,
  margin: '0 auto',
  lineHeight: 1.2,
})

const ArtistsInResidenceSection = styled(BaseSection)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  padding: '5.7em 1em 2em',
})

const ArtistsInResidenceSectionTitle = styled(SecondarySectionTitle)(
  {
    marginBottom: '.12em',
  },
  mq({
    fontSize: ['2.75em', '2em'],
  }),
)

const ResidentArtist = styled('div')(
  mq({
    width: ['16.6666%', '50%'],
    marginTop: [0, '1em'],
  }),
)

const ResidentArtistImage = styled(FluidImage)({
  maxWidth: 100,
  margin: '0 auto',
})

const ResidentArtistName = styled('div')({
  fontFamily: fonts.garamond.condensed,
  fontSize: '1.2em',
  lineHeight: 1,
  position: 'relative',
  top: '.2em',
  marginTop: '.2em',
  justifyContent: 'center',
})

const ResidentArtistLocation = styled('div')({
  fontSize: '14px',
  textTransform: 'uppercase',
})

const WeekendArtistsSection = styled(BaseSection)(
  mq({
    display: 'flex',
    flexDirection: 'row',
    padding: ['4.5em 1em 1.6em', '4.5em 0 1.6em'],
    flexWrap: 'wrap',
    justifyContent: 'center',
  }),
)

const WeekendArtistsSectionTitle = styled(SecondarySectionTitle)(
  {
    fontFamily: fonts.garamond.condensed,
    fontSize: '3em',
    position: 'relative',
    top: '.1em',
  },
  mq({
    fontSize: ['3em'],
    lineHeight: [1, 0.9],
  }),
)

const WeekendArtist = styled('div')(
  {
    width: '25%',
    textAlign: 'center',
    fontSize: '1.5em',
    padding: '0 .5em',
    height: '3em',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexGrow: 0,
  },
  mq({
    width: ['25%', '50%'],
  }),
)

const WeekendArtistName = styled('div')({})

export default ArtistsSection
