import React, { FC } from 'react'

import { ThankYouSectionData } from '../../types'
import { fonts, styled } from '../../styles'
import TextBlock from '../TextBlock'
import Title from '../Title'
import { withLineBreaks } from '../../lib/RenderUtils'
import Section from '../Section'

const ThankYouSection: FC<{
  section: ThankYouSectionData
}> = ({ section }) => {
  return (
    <Container>
      <Title>{withLineBreaks(section.section_title?.text || '')}</Title>
    </Container>
  )
}

const Container = styled(Section)({})

export default ThankYouSection
