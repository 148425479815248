import React, { FC } from 'react'

import { AboutUsSectionData } from '../../types'
import { fonts, mq, styled, widthToFill } from '../../styles'
import Title from '../Title'
import { withLineBreaks } from '../../lib/RenderUtils'
import FluidImage from '../base/FluidImage'
import Section from '../Section'
import P from '../base/P'
import Caption from '../Caption'

const AboutUsSection: FC<{
  section: AboutUsSectionData
}> = ({ section }) => {
  return (
    <>
      <Container>
        <Title>{withLineBreaks(section.section_title?.text || '')}</Title>
        <Image image={section.image} />
        <Caption>{section.image?.alt}</Caption>
        <Text>{section.text?.text}</Text>

        <Subtitle>
          {withLineBreaks(section.collaborators_list_title?.text || '')}
        </Subtitle>
        <CollaboratorsList>
          {section.collaborators?.raw.map((t, i) => {
            return (
              <span key={i}>
                {t.text}
                <br />
              </span>
            )
          })}
        </CollaboratorsList>

        <SecondaryText>{section.secondary_text?.text}</SecondaryText>
        <TertiaryText>{section.tertiary_text?.text}</TertiaryText>
        <FinalImage image={section.final_image} />
      </Container>
    </>
  )
}

const Container = styled(Section)({})

const Image = styled(FluidImage)(
  {
    margin: '0 auto',
    marginTop: '3em',
  },
  mq({
    width: [800, '100%'],
    marginTop: ['3em', '1.5em'],
  }),
)

const Text = styled(P)(
  {
    maxWidth: 600,
    margin: '1em auto',
  },
  widthToFill(700),
)

const SecondaryText = styled('div')(
  {
    fontFamily: fonts.topol,
    textAlign: 'center',
    margin: '1em auto',
    marginTop: 'em',
    fontSize: '1.5em',
  },
  widthToFill(525),
  mq({
    marginTop: ['4em', '1.5em'],
  }),
)

const TertiaryText = styled('div')(
  {
    fontFamily: fonts.garamond.condensed,
    fontSize: '3.5em',
    textAlign: 'center',
    margin: '.25em auto',
    marginBottom: '.72em',
  },
  widthToFill(800),
  mq({
    lineHeight: [1, 0.9],
  }),
)

const FinalImage = styled(FluidImage)({
  width: 100,
  margin: '0 auto',
})

const Subtitle = styled('div')(
  {
    fontSize: '2.5em',
    lineHeight: '1em',
    textAlign: 'center',
    paddingTop: '1em',
    margin: '0 auto',
    marginBottom: '1em',
  },
  widthToFill(620),
  mq({
    paddingTop: ['2em', 0],
    marginTop: [0, '.75em'],
    marginBottom: ['1em', '.75em'],
  }),
)

const CollaboratorsList = styled('div')(
  {
    columnCount: 4,
    fontSize: '1em',
    maxWidth: 800,
    margin: '0 auto',
    lineHeight: 1.5,
  },
  mq({
    width: [800, 'auto'],
    columnCount: [4, 2],
    textAlign: ['left', 'center'],
  }),
)

export default AboutUsSection
