import React, { FC } from 'react'

import { BatchesSectionData } from '../../types'
import { colors, fonts, mq, styled } from '../../styles'
import Button from '../Button'
import H1 from '../base/H1'
import useShopUrl from '../../lib/useShopUrl'
import Section from '../Section'

const BatchesSection: FC<{
  section: BatchesSectionData
}> = ({ section }) => {
  const shopUrl = useShopUrl()
  let renderedButton = false

  return (
    <Container withoutTitle={true}>
      {section.batches?.map((b, i) => {
        const renderButton = !b?.sold_out && !renderedButton
        if (renderButton) renderedButton = true
        return (
          <BatchContainer isLast={i + 1 === section.batches?.length}>
            <BatchTitle>{b?.name?.text}</BatchTitle>
            <BatchDescription>{b?.description?.text}</BatchDescription>
            {renderButton && (
              <ButtonContainer>
                <BigButton href={shopUrl}>ORDER</BigButton>
              </ButtonContainer>
            )}
          </BatchContainer>
        )
      })}
    </Container>
  )
}

const Container = styled(Section)({
  alignItems: 'center',
  backgroundColor: 'rgb(181,124,64)',
})

const BigButton = styled('a')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40vw',
  margin: '0 auto',
  height: '10vw',
  borderRadius: '5vw',
  fontSize: '6vw',
  border: 0,
  fontFamily: fonts.topol,
  backgroundColor: colors.darkBrown,
  textDecoration: 'none',
  color: colors.lightBrown,
})

const BatchContainer = styled('div')<{ isLast: boolean }>(
  {
    textAlign: 'center',
  },
  (props) => {
    return {
      marginBottom: props.isLast ? 0 : '4em',
    }
  },
)

const BatchTitle = styled(H1)({
  lineHeight: 1,
  position: 'relative',
  top: '.15em',
})

const BatchDescription = styled('div')({
  fontFamily: fonts.garamond.condensed,
  fontSize: '2em',
})

const ButtonContainer = styled('div')({
  marginBottom: '.5em',
})

export default BatchesSection
