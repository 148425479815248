import React, { FC, useCallback, useRef, useState } from 'react'

import { Maybe, WhatToExpectItemFragment } from '../../../graphql-types'
import useAppearEffect from '../../lib/useAppearEffect'
import { styled, mq, widthToFill, colors } from '../../styles'
import { WhatToExpectSectionData } from '../../types'
import Section from '../Section'
import Title from '../Title'

const WhatToExpectSection: FC<{ section: WhatToExpectSectionData }> = ({
  section,
}) => {
  return (
    <Container>
      <Title>{section.section_title?.text}</Title>
      <CubesContainer>
        {section.items &&
          section.items.map((item, index) => (
            <WhatToExpectItem item={item} key={index} />
          ))}
      </CubesContainer>
    </Container>
  )
}

const WhatToExpectItem: FC<{
  item: Maybe<WhatToExpectItemFragment>
}> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isTouch, setIsTouch] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)
  const { hasAppeared } = useAppearEffect(containerRef)

  const handleMouseOver = useCallback(
    (e) => {
      if (!isTouch) setIsOpen(true)
    },
    [isTouch],
  )

  const handleMouseOut = useCallback(
    (e) => {
      if (!isTouch) setIsOpen(false)
    },
    [isTouch],
  )

  const handleTouchStart = useCallback(() => {
    setIsTouch(true)
  }, [])

  const handleTouchEnd = useCallback(() => {
    setIsTouch(true)
  }, [])

  const handleClick = useCallback(() => {
    if (isTouch) setIsOpen((open) => !open)
  }, [isTouch])

  return (
    <Cube
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onClick={handleClick}>
      <CubeContent ref={containerRef}>
        <BackgroundImage
          style={{
            backgroundImage: hasAppeared
              ? `url('${item?.image?.fluid?.src}')`
              : undefined,
          }}></BackgroundImage>
        <BodyText isOpen={isOpen}>{item?.text?.text}</BodyText>
      </CubeContent>
    </Cube>
  )
}

const Container = styled(Section)({
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const CubesContainer = styled('div')(
  {
    width: 1000,
    paddingTop: 15,
  },
  widthToFill(1000),
  mq({
    columns: [5, 2],
    columnGap: [20, 10],
    marginBottom: [-20, -10],
  }),
)

const Cube = styled('div')(
  {
    border: 'solid',
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 12,
  },
  mq({
    marginBottom: [20, 10],
  }),
)

const CubeContent = styled('div')({
  display: 'flex',
  borderRadius: 12,
  overflow: 'hidden',
  WebkitMaskImage: '-webkit-radial-gradient(white, black)',
  position: 'relative',
  width: '100%',
  paddingBottom: '50%',
  zIndex: 1,
  transform: 'translate3d(0,0,0)',
})

const BackgroundImage = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  pointerEvents: 'none',
  transform: 'translate3d(0,0,0)',
  mixBlendMode: 'multiply',
})

const BodyText = styled('div')<{ isOpen: boolean }>((props) =>
  mq({
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: -1,
    right: 0,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '1em',
    width: '100%',
    height: 'inherit',
    transition: 'transform .2s ease-out',
    pointerEvents: 'none',
    borderBottom: '1px solid black',
    backgroundColor: colors.mediumBrown,
    padding: [10, 5, 5, 5],
    transform: props.isOpen ? 'translateY(-100%)' : 'translateY(0)',
  }),
)

export default WhatToExpectSection
