import React, { FC } from 'react'

import { SmallOrderSectionData } from '../../types'
import { colors, fonts, mq, styled } from '../../styles'
import Section from '../Section'
import P from '../base/P'
import ShopButton from '../ShopButton'
import { Maybe } from '../../../graphql-types'

const SmallOrderSection: FC<{
  section: SmallOrderSectionData
}> = ({ section }) => {
  return (
    <Container>
      <Title>Batch Eight in Production</Title>
      <Text>
        The Oda System is produced in limited editions and made by hand — and
        has sold out seven times. Sign up below to be the first to gain access
        to Batch Eight.{' '}
      </Text>

      {/* <BatchesContainer>
        {section.order_section?.document?.data?.batches?.map((batch, i) => {
          return (
            <Batch key={i} soldOut={batch?.sold_out}>
              <BatchContent>
                <BatchName soldOut={batch?.sold_out}>
                  {batch?.name?.text}
                </BatchName>
                <BatchText>
                  {batch?.sold_out ? (
                    'Sold Out'
                  ) : (
                    <>
                      <AvailableText>Now Available</AvailableText>
                      Delivery {batch?.delivery_info}
                    </>
                  )}
                </BatchText>
              </BatchContent>
            </Batch>
          )
        })}
      </BatchesContainer> */}
      <OrderButton location="top-order">JOIN THE WAITLIST</OrderButton>
      <BottomText>
        (Psssst… we’re also hard at work on a new kind of membership available
        to stream wherever you are.{' '}
        <a href="mailto:hello@oda.co?subject=I want streaming access">
          Drop us a note
        </a>{' '}
        and tell us some of your favorite sounds and we’ll spill the beans.)
      </BottomText>
    </Container>
  )
}

const Container = styled(Section)({
  backgroundColor: colors.darkBrown,
  color: colors.lightBrown,
})

const Title = styled('div')(
  mq({
    fontSize: '6em',
    fontFamily: fonts.garamond.condensed,
    textAlign: 'center',
    lineHeight: [0.9, 0.9],
    maxWidth: 500,
    margin: '0 auto',
    marginTop: '.2em',
    '-webkit-font-smoothing': 'antialiased',
  }),
)

const Text = styled('div')(
  mq({
    // fontSize: '4em',
    fontFamily: fonts.monosten.light,
    textAlign: 'center',
    // lineHeight: [1, 0.9],
    maxWidth: 400,
    margin: '0 auto',
    marginTop: '.5em',
    marginBottom: '1em',
    '-webkit-font-smoothing': 'antialiased',
  }),
)

const BottomText = styled('div')(
  mq({
    // fontSize: '4em',
    fontFamily: fonts.monosten.light,
    textAlign: 'center',
    // lineHeight: [1, 0.9],
    maxWidth: 600,
    fontSize: 12,
    margin: '0 auto',
    marginTop: '1em',
    '-webkit-font-smoothing': 'antialiased',
  }),
)

const BatchesContainer = styled.div(
  mq({
    width: '100%',
    maxWidth: 650,
    columnGap: '1em',
    margin: '1.5em auto 2em',
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: ['row', 'column'],
  }),
)

const Batch = styled('div')<{ soldOut?: Maybe<boolean> }>(({ soldOut }) =>
  mq({
    width: ['33%', 'auto'],
    opacity: soldOut ? 0.2 : 1,
  }),
)

const BatchContent = styled(P)(
  mq({
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    border: `1px solid ${colors.lightBrown}`,
    borderRadius: '12px',
    marginBottom: [0, '.5em'],
    height: ['100%', 'auto'],
  }),
)

const BatchName = styled(P)<{ soldOut?: Maybe<boolean> }>(({ soldOut }) =>
  mq({}),
)

const BatchText = styled(P)(mq({}))

const AvailableText = styled(P)(
  mq({
    fontSize: '1.5em',
    fontFamily: fonts.topol,
  }),
)

const OrderButton = styled(ShopButton)(
  mq({
    width: '100%',
    marginTop: '.5em',
    maxWidth: 1000,
    fontSize: ['6em', '2.5em'],
    borderRadius: [75, 50],
    height: [150, 100],
    letterSpacing: [1, 0],
    paddingBottom: ['.1em', '.3em'],
  }),
)

export default SmallOrderSection
