import React, { FC, useEffect, useRef } from 'react'
import { Maybe, SectionListFragment } from '../../graphql-types'
import { recordPageView } from '../lib/Analytics'
import { styled } from '../styles'
import { SectionData } from '../types'
import FrontMatter from './FrontMatter'
import AboutOdaSection from './sections/AboutOdaSection'
import AboutUsSection from './sections/AboutUsSection'
import AcousticDesignSection from './sections/AcousticDesignSection'
import ArtistNamesSection from './sections/ArtistNamesSection'
import ArtistsSection from './sections/ArtistsSection'
import BatchesSection from './sections/BatchesSection'
import EconomicsSection from './sections/EconomicsSection'
import HeroMusicSection from './sections/HeroMusicSection'
import HeroSpeakerSection from './sections/HeroSpeakerSection'
import HowOdaFeelsSection from './sections/HowOdaFeelsSection'
import HowYouListenSection from './sections/HowYouListenSection'
import OverviewSection from './sections/OverviewSection'
import PreorderSection from './sections/PreorderSection'
import ProgramHeroSection from './sections/ProgramHeroSection'
import ProgrammingSection from './sections/ProgrammingSection'
import SignupSection from './sections/SignupSection'
import SmallOrderSection from './sections/SmallOrderSection'
import SocialProofSection from './sections/SocialProofSection'
import SoundSection from './sections/SoundSection'
import SpeakerDesignSection from './sections/SpeakerDesignSection'
import ThankYouSection from './sections/ThankYouSection'
import WhatToExpectSection from './sections/WhatToExpectSection'

interface SectionListProps {
  sections?: Maybe<SectionListFragment>
}

const tidySectionData = (data?: Maybe<SectionListFragment>): SectionData[] => {
  const sections = data?.sections?.map((s) => {
    const type = s!.section!.type! as SectionData['type']
    return {
      type,
      ...s?.section?.document?.data,
    }
  })
  return sections || []
}

const sectionOrder = [
  'splash_social_proof_section',
  'splash_sound_section',
  'splash_speaker_design_section',
  'splash_acoustic_design_section',
  'splash_economics_section',
  'splash_about_us_section',
  'splash_what_to_expect_section',
  'splash_mailing_list_section',
]

const SectionList: FC<SectionListProps> = ({ sections }) => {
  const newSections = [
    ...tidySectionData(sections),
    { type: 'splash_how_you_listen_section' },
    { type: 'splash_hero_program_section' },
  ]
    .filter((s) => sectionOrder.indexOf(s.type) !== -1)
    .reduce((memo, s) => {
      memo[s.type] = s
      return memo
    }, {})

  const sectionsToUse = sectionOrder.map((k) => {
    if (!newSections[k]) {
      console.log('missing section for', k)
      return { type: k }
    }
    return newSections[k]
  })

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!window.IntersectionObserver || !window.ga) return

    let options = {
      rootMargin: '-25%',
      threshold: 0,
    }
    let pageViewTimer: ReturnType<typeof setTimeout>
    const triggerPageView = (pageName: string) => {
      if (pageName === 'splash_music_hero_section-0') pageName = '/'
      if (pageViewTimer) clearTimeout(pageViewTimer)
      pageViewTimer = setTimeout(() => {
        recordPageView(pageName)
      }, 500)
    }

    const observer = new IntersectionObserver((entries, b) => {
      const active = entries
        .filter((entry) => entry.isIntersecting)
        .map((entry) => (entry.target as HTMLDivElement).dataset.section)
      if (active.length === 1 && active[0]) {
        triggerPageView(active[0])
      }
    }, options)
    const container = containerRef.current
    if (!container) return
    for (let i = 0; i < container.children.length; ++i) {
      observer.observe(container.children[i])
    }
  }, [])

  return (
    <SectionContainer ref={containerRef}>
      <FrontMatter />
      {sectionsToUse?.map((s, i) => (
        <div key={i} data-section={`${s.type}-${i}`}>
          {renderSection(s, i)}
        </div>
      ))}
    </SectionContainer>
  )
}

const SectionContainer = styled('div')({}, (props) => ({}))

const renderSection = (section: SectionData) => {
  if (section.type === 'splash_hero_music_section') {
    return <HeroMusicSection section={section} />
  } else if (section.type === 'splash_about_oda_section') {
    return <AboutOdaSection />
  } else if (section.type === 'splash_hero_program_section') {
    return <ProgramHeroSection />
  } else if (section.type === 'splash_how_you_listen') {
    return <HowYouListenSection section={section} />
  } else if (section.type === 'splash_hero_speaker_section') {
    return <HeroSpeakerSection section={section} />
  } else if (section.type === 'splash_social_proof_section') {
    return <SocialProofSection section={section} />
  } else if (section.type === 'splash_overview_section') {
    return <OverviewSection section={section} />
  } else if (section.type === 'splash_programming_section') {
    return <ProgrammingSection section={section} />
  } else if (section.type === 'splash_about_us_section') {
    return <AboutUsSection section={section} />
  } else if (section.type === 'splash_acoustic_design_section') {
    return <AcousticDesignSection section={section} />
  } else if (section.type === 'splash_batches_section') {
    return <BatchesSection section={section} />
  } else if (section.type === 'splash_economics_section') {
    return <EconomicsSection section={section} />
  } else if (section.type === 'splash_how_it_feels_section') {
    return <HowOdaFeelsSection section={section} />
  } else if (section.type === 'splash_how_you_listen_section') {
    return <HowYouListenSection section={section} />
  } else if (section.type === 'splash_mailing_list_section') {
    return <SignupSection section={section} />
  } else if (section.type === 'splash_sound_section') {
    return <SoundSection section={section} />
  } else if (section.type === 'splash_speaker_design_section') {
    return <SpeakerDesignSection section={section} />
  } else if (section.type === 'splash_thank_you_section') {
    return <ThankYouSection section={section} />
  } else if (section.type === 'splash_what_to_expect_section') {
    return <WhatToExpectSection section={section} />
  } else if (section.type === 'splash_artists_section') {
    return <ArtistsSection section={section} />
  } else if (section.type === 'splash_artists_section') {
    return <ArtistsSection section={section} />
  } else if (section.type === 'splash_preorder_section') {
    return <PreorderSection section={section} />
  } else if (section.type === 'splash_artist_names_section') {
    return <ArtistNamesSection section={section} />
  } else if (section.type === 'splash_small_order_section') {
    return <SmallOrderSection section={section} />
  } else {
    return null
  }
}

export default SectionList
