import React from 'react'
import Summer from '../../images/summer.png'
import { withLineBreaks } from '../../lib/RenderUtils'
import { mq, styled, widthToFill } from '../../styles'
import FluidImage from '../base/FluidImage'
import P from '../base/P'
import Section from '../Section'
import ShopButton from '../ShopButton'
import Title from '../Title'

const sectionData = {
  seasonTitle: 'Summer Season',
  programSummary:
    'A celebration of life returning.\nContinously live, with appearances by:',
  image: '/summer.png',
  broadcasts: [
    {
      title: 'Terry Riley',
      description:
        'A long, slow vibration. Keyboard improvisations and North Indian Ragas. Ideas, free, as they arise.',
      location: 'Yamanashi Prefecture, Japan',
      image: '/program/program-terryriley.jpg',
    },
    {
      title: 'A Wisconsin Sheep Farm',
      description:
        'The patter of tiny hooves. A rustle in the hay. A ram named Maynard. Gravel crunching underfoot.',
      location: 'Amery, Wisconsin',
      image: '/program/program-sheepfarm.jpg',
    },
    {
      title: 'Don Bryant & Ann Peebles',
      description:
        'Rain. Records from the 70s. Songs Don wrote for Ann. A collaboration as old as love.',
      location: 'Memphis, Tennessee',
      image: '/program/program-bryant-peebles.jpg',
    },

    {
      title: 'A Village in Southern Spain',
      description:
        'Birds and wind in the valley. The drumbeats of a centuries-old Holy Week tradition.',
      location: 'Cuenca, Spain',
      image: '/program/program-cuenca.jpg',
    },
    {
      title: 'KeiyaA',
      description:
        'Dense lyricism. A powerful, sultry voice. Hood-femme intellect and prowess juxtaposed with hard, warm, and warped beats.',
      location: 'Brooklyn, NY',
      image: '/program/program-keiyaa.jpg',
    },
    // {
    //   title: 'Horses in the Catskills',
    //   description:
    //     'The patter of tiny hooves. A rustle in the hay. A ram named Maynard. Gravel crunching underfoot.',
    //   location: 'Amery, Wisconsin',
    //   image: '/program/program-sheepfarm.jpg',
    // },
    {
      title: 'A Forest in Vermont',
      description:
        'Spring rain pattering on new leaves. Chipmunks foraging. Bird calls and branches in the wind.',
      location: 'Vermont, USA',
      image: '/program/program-vermont.jpg',
    },
    {
      title: 'Laraaji',
      description:
        'A deep cosmic tone baptism. Zither and hammered dulcimer. Laughing, joy, and release. Communion with the beyond.',
      location: 'Harlem, NYC',
      image: '/program/program-laraaji.jpg',
    },
  ],
  otherArtists: [
    'BEVERLY GLENN-COPELAND',
    'LARAAJI',
    'AROOJ AFTAB',
    'MARY LATTIMORE',
    'BLUES LEGENDS FROM MEMPHIS ROYAL STUDIOS',
    'JAPANESE TEMPLES WITH YOSHIMIO (Boredoms, OOIOO)',
    'SOUNDS OF COLORADO & JOSEPHINE FOSTER',
    'LONNIE HOLLEY',
    'SOUND SCULPTURES IN UPSTATE NEW YORK',
    'JAMIE LIDELL',
    'DESERT SOUNDS OF ARCOSANTI',
    'MIDNIGHT SUN CELEBRATION OF SWEDISH MIDSUMMER',
    'ANIMAL SANCTUARIES',
  ],
  body: `Sound has the power to transport us — to distant lands and into deeper mental states. Join us
  this summer as we put our collective ear up to the world, journeying to remote Swedish villages
  and bustling Japanese cities, buzzing secret gardens and idyllic animal sanctuaries, rhythmic
  coastlines and iconic recording studios. Together, we’ll rediscover the magic of the everyday
  through uninterrupted broadcasts from the most unexpected places and intimate live
  performances.`,
}

const ProgramHeroSection = () => {
  return (
    <Container>
      <Title>{withLineBreaks(sectionData.seasonTitle)}</Title>
      {/* <P>June 21 – September 1</P> */}
      <ItemImage
        image={{
          fluid: {
            src: Summer,
            srcSet: Summer,
            aspectRatio: 1.2689,
          },
        }}
      />

      <JourneyContainer>
        <JourneyTopText>Our Summer season features</JourneyTopText>
        <JourneyTitle>
          A JOURNEY
          <br />
          WITH WILL OLDHAM
        </JourneyTitle>
        <JourneyDescriptionText>
          Will Oldham introduces the Oda world to aspects of the musical
          universes existing in Louisville KY
        </JourneyDescriptionText>
      </JourneyContainer>

      <OtherPerformersContainer>
        {/* <P>And appearances by</P> */}
        <OtherPerformersList>
          {renderNames(sectionData.otherArtists)}
        </OtherPerformersList>
        <P>And more...</P>
      </OtherPerformersContainer>

      <BroadcastDescription>
        <BroadcastDescriptionText>
          Sound has the power to transport us — to distant lands and into deeper
          mental states. Join us this summer as we put our collective ear up to
          the world, journeying to remote Swedish villages and bustling Japanese
          cities, buzzing secret gardens and idyllic animal sanctuaries, the
          rhythmic shorelines of Maine and the iconic recording studios of the
          American South.
        </BroadcastDescriptionText>
        <BroadcastDescriptionText>
          Together, we’ll rediscover the magic of the everyday through
          uninterrupted broadcasts from the most unexpected places and intimate
          live performances with legendary musicians.
        </BroadcastDescriptionText>
      </BroadcastDescription>

      <OrderButton location={'summer-program'} colorScheme="dark">
        Become a Member
      </OrderButton>
    </Container>
  )
}

const JourneyContainer = styled('div')({
  marginTop: '0em',
  marginBottom: '0em',
})

const JourneyTopText = styled(P)({
  marginBottom: '.5em',
})

const JourneyTitle = styled('div')({
  fontSize: '3em',
  lineHeight: 1,
  textAlign: 'center',
})

const JourneyDescriptionText = styled(P)({
  width: 325,
  margin: '0 auto',
  marginTop: '1em',
  marginBottom: '4em',
})

const ArtistName = styled('div')({
  fontSize: '2em',
  textAlign: 'center',
})

const renderNames = (names?: string[]) => {
  if (!names) return null

  const items = []
  for (let i = 0; i < names.length; ++i) {
    items.push(<Name key={`name-${i}`}>{names[i]}</Name>)
    if (i + 1 !== names.length) {
      items.push(<Dot key={`dot-${i}`}>{' ● '}</Dot>)
    }
  }
  return items
}

const Container = styled(Section)(
  {
    backgroundRepeat: 'no-repeat',
    minHeight: '900px',
    backgroundPosition: 'bottom center',
    flexDirection: 'column',
  },
  mq({
    backgroundSize: ['auto 65%', '100% auto', '100% auto'],
    minHeight: ['auto'],
    // paddingTop: ['6em', '8em'],
    // paddingBottom: ['7rem', '4rem'],
  }),
)

const ItemImage = styled(FluidImage)(
  mq({
    margin: ['5em auto 5em', '2em auto 2em'],
    width: [700, '100%'],
  }),
)

const BroadcastDescription = styled('div')(
  { textAlign: 'center' },
  mq({
    lineHeight: 1.2,
    maxWidth: 800,
    margin: '0 auto',
    display: ['flex'],
  }),
)

const BroadcastDescriptionText = styled(P)({
  padding: '0 10px',
  width: '50%',
})

const OtherPerformersContainer = styled('div')(
  mq({
    marginBottom: ['4.5em', '2em'],
  }),
)

const OtherPerformersTitle = styled('div')(
  mq({
    textAlign: 'center',
    marginBottom: '2.5em',
    fontSize: [32, 24],
  }),
)
const OtherPerformersList = styled('div')(
  mq({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: [1.2],
    margin: '.5em auto',
    marginTop: '1rem',
    padding: '0 20px',
  }),
  widthToFill(900),
)

const Name = styled('div')(
  mq({
    textAlign: 'center',
    fontSize: [32, 24],
  }),
)

const Dot = styled('div')({
  margin: '0 .5em',
})

const JoinUsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 20px',
})

const OrderButton = styled(ShopButton)(
  mq({
    margin: '0 auto',
    width: ['auto', '100%'],
    marginTop: ['3rem', '2rem'],
    fontSize: ['3em', '2em'],
    padding: ['2px 100px 8px'],
  }),
)

export default ProgramHeroSection
