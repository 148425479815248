import React, { FC } from 'react'

import { colors, fonts, mq, styled, widthToFill } from '../../styles'
import FluidImage, { ImageFragment } from '../base/FluidImage'
import Section from '../Section'
import P from '../base/P'
import { withLineBreaks } from '../../lib/RenderUtils'
import Title from '../Title'

const data = {
  title: 'How You Listen',
  summary:
    'Oda is a seasonal subscription to live sound available to stream on The Oda System.',
  items: [
    {
      image: '/listening-membership.png',
      title: 'The Membership',
      content: `Each season, we explore a new theme through curated weekly performances and field
        transmissions from around the world – always live, always in real time. You’ll join a dedicated
        community of listeners and receive access to artists broadcasting directly to your home from
        theirs. Through expansive soundscapes and intimate concerts, you’ll discover a new connection to the world through sound. `,
    },
    // {
    //   image: '/listening-lighthouse.png',
    //   title: 'The Lighthouse',
    //   content:
    //     "Oda's brain. It lights up when Oda is live, and relays sound from our servers to your speakers, connecting you directly to artists and places around the world.",
    // },
    {
      image: '/listening-speakers.png',
      title: 'The Oda System',
      content: `Our home listening system is designed to create a sonic experience that is warm, enveloping,
      and direct. Our Lighthouse Receiver connects you directly to our broadcasts, lighting up when a
      new transmission is ready for your ears. Our handmade speakers — constructed elegantly with
      wood that resonates like a cello — act as portals into distant and intimate worlds. Like all well
      designed tools, The Oda System delivers both function and magic, creating impossible wonder
      through immersive sound, all while seamlessly integrating into the specific universe of your
      home.`,
    },
  ],
}

const makeFluidImage = (src: string, aspect: number): ImageFragment => {
  return {
    fluid: { src: src, srcSet: src, aspectRatio: aspect },
  }
}

const HowYouListenSection: FC<{
  section: any
}> = ({ section }) => {
  return (
    <Container>
      <Title>{data.title}</Title>
      <Summary>{withLineBreaks(data.summary)}</Summary>

      {data.items?.map((item, i) => {
        return (
          <Item key={i} index={i}>
            <ItemImageContainer>
              <ItemImage
                transparent={true}
                image={makeFluidImage(item.image, 1)}></ItemImage>
            </ItemImageContainer>
            <ItemContent>
              <ItemTitle>{withLineBreaks(item?.title)}</ItemTitle>
              <ItemText>{item.content}</ItemText>
            </ItemContent>
          </Item>
        )
      })}
    </Container>
  )
}

const Container = styled(Section)({
  display: 'block',
  paddingTop: ['6em', '8em'],
})

const Summary = styled('div')(
  mq({
    fontSize: [48, 32],
    fontFamily: fonts.garamond.condensed,
    textAlign: 'center',
    maxWidth: 925,
    margin: ['0 auto 2em', '0 auto .5em'],
    lineHeight: 1.2,
  }),
)

const Item = styled('div')<{ index: number }>({}, ({ index }) =>
  mq({
    display: 'flex',
    maxWidth: 1200,
    padding: ['0 20px 6em', '0 20px 3em'],
    margin: ['3em auto 0', '0 auto 0'],
    paddingBottom: ['6em', '0em'],
    alignItems: 'center',
    flexDirection: [index % 2 == 1 ? 'row-reverse' : 'row-row', 'column'],
  }),
)

const ItemImageContainer = styled('div')(
  mq({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ['50%', '100%'],
  }),
)

const ItemImage = styled(FluidImage)(
  mq({
    width: '100%',
    maxWidth: '350px',
    margin: '0 auto',
    height: '100%',
  }),
)

const ItemContent = styled('div')(
  mq({
    width: ['50%', '100%'],
  }),
)

const ItemTitle = styled('div')(
  {
    margin: '0 auto',
    textAlign: 'center',
    marginTop: ['1em', 0],
    marginBottom: '.75em',
    fontSize: '2em',
  },
  widthToFill(500),
)

const ItemSubtitle = styled('div')(
  {
    fontSize: '1.2em',
    fontFamily: fonts.topol,
    margin: '0 auto',
    textAlign: 'center',
    marginTop: '.5em',
    marginBottom: '.8em',
  },
  widthToFill(350),
)

const ItemText = styled(P)(
  {
    margin: '0 auto',
    lineHeight: 1.2,
    fontFamily: fonts.garamond.condensed,
  },
  mq({
    fontSize: ['32px', '24px'],
    maxWidth: [710, 325],
    padding: ['0 20px', 0],
  }),
)

export default HowYouListenSection
