import React, { FC } from 'react'

import { AcousticDesignSectionData } from '../../types'
import { fonts, mq, styled } from '../../styles'
import Title from '../Title'
import { withLineBreaks } from '../../lib/RenderUtils'
import FluidImage from '../base/FluidImage'
import Section from '../Section'
import P from '../base/P'
import Caption from '../Caption'

const AcousticDesignSection: FC<{
  section: AcousticDesignSectionData
}> = ({ section }) => {
  return (
    <Container>
      <Title>{withLineBreaks(section.section_title?.text || '')}</Title>
      {section.items?.map((item, i) => {
        return (
          <Item key={i} isLast={i + 1 === section.items?.length}>
            <ItemImage image={item?.image} />
            <ItemImageCaption>{item?.image?.alt}</ItemImageCaption>
            <ItemTitle>{item?.title?.text}</ItemTitle>
            <ItemText>{item?.text?.text}</ItemText>
          </Item>
        )
      })}
    </Container>
  )
}

const Container = styled(Section)({
  alignItems: 'center',
})

const Item = styled('div')<{ isLast: boolean }>(
  {
    maxWidth: 650,
    margin: '0 auto',
    textAlign: 'center',
    marginTop: '3em',
  },
  (props) => ({
    marginBottom: props.isLast ? 0 : '1em',
  }),
)

const ItemImage = styled(FluidImage)(
  {
    margin: '0 auto',
  },
  mq({
    maxWidth: [400, '100%'],
  }),
)

const ItemImageCaption = styled(Caption)({})

const ItemTitle = styled('div')({
  fontSize: '1.5em',
  marginBottom: '.2em',
})

const ItemText = styled('div')({
  fontFamily: fonts.garamond.condensed,
  fontSize: '1.5em',
})

export default AcousticDesignSection
