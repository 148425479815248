import React, { FC } from 'react'

import { OverviewSectionData } from '../../types'
import { colors, fonts, mq, styled, widthToFill } from '../../styles'
import FluidImage from '../base/FluidImage'
import Section from '../Section'
import P from '../base/P'
import { withLineBreaks } from '../../lib/RenderUtils'
import Title from '../Title'
import Calendar from '../../images/calendar.jpg'

import ShopButton from '../ShopButton'

const data = {
  title: 'A Seasonal Membership\nfor Live Listening',
  summary:
    'Singular performances and soundscapes from around the globe. Beautiful speakers built for immersion. All live, in a dedicated space in your living room.',
  image: '/overview.png',
  seasonSummary:
    'Our programming follows the seasons. Each Spring, Summer, Fall, and Winter, we curate a unique, thematic series of live broadcasts.',
  items: [
    {
      title: 'Soundscapes From\nAround the World',
      content: `Oda invites you to experience wonder and connection through sound. Each season
        you’ll hear continuous soundscapes broadcast live that transport you to distant cities
        and remote landscapes around the globe.`,
    },
    {
      title: 'Live Music From the Homes\nof Incredible Artists',
      content: `Each week, you’ll hear from a curated roster of artists who will create a unique
        performance exclusively for Oda members. In singular moments or journeys that unfold
        over weeks, listeners connect directly with each artist as they broadcast live from their
        corner of the world.`,
    },
  ],
}

const OverviewSection: FC<{
  section: OverviewSectionData
}> = ({ section }) => {
  return (
    <Container>
      <Title>{withLineBreaks(data.title)}</Title>
      {/* <Summary>{withLineBreaks(data.summary)}</Summary> */}
      <ItemImage
        image={{
          fluid: { src: Calendar, srcSet: Calendar, aspectRatio: 1.444 },
        }}></ItemImage>
      {/* <SeasonSummary>{withLineBreaks(data.seasonSummary)}</SeasonSummary> */}
      <TextContainer>
        {data.items?.map((item, i) => {
          return (
            <Item key={i} isLast={i === (data.items?.length || 0) - 1}>
              {/* <ItemTitle>{withLineBreaks(item?.title)}</ItemTitle> */}
              <ItemText>{item.content}</ItemText>
            </Item>
          )
        })}
      </TextContainer>
    </Container>
  )
}

const Container = styled(Section)({
  flexDirection: 'column',
  // paddingTop: ['6em', '8em'],
})

const Summary = styled('div')(
  mq({
    fontSize: [48, 32],
    fontFamily: fonts.garamond.condensed,
    textAlign: 'center',
    maxWidth: 925,
    margin: '1.5em auto',
    lineHeight: 1,
  }),
)

const SeasonSummary = styled('div')(
  mq({
    maxWidth: 925,
    margin: '1.5em auto 1.25em',
    textAlign: 'center',
    fontFamily: fonts.garamond.condensed,
    fontSize: [48, 32],
    lineHeight: 1,
  }),
)

const TextContainer = styled('div')(
  mq({
    display: 'flex',
    flexDirection: ['row', 'column'],
    alignItems: 'flex-start',
    paddingTop: ['5em', '5em'],
  }),
)

const Item = styled('div')<{ isLast: boolean }>({}, (props) =>
  mq({
    maxWidth: 500,
    margin: '0 auto',
    marginBottom: [0, '3em'],
    paddingBottom: ['0em', '0em'],
    ':last-child': {
      marginBottom: 0,
    },
  }),
)

const ItemImage = styled(FluidImage)(
  {},
  mq({
    width: [400, '100%'],
    margin: ['5em auto 1em', '2em auto 2em'],
  }),
)

const ItemTitle = styled('div')(
  mq({
    margin: '0 auto',
    textAlign: 'center',
    marginTop: [0, 0],
    marginBottom: '.75em',
    fontSize: ['2em', '1.5em'],
  }),
  widthToFill(500),
)

const ItemText = styled(P)(
  {
    margin: '0 auto',
    lineHeight: 1.2,
    fontFamily: fonts.garamond.condensed,
  },
  mq({
    fontSize: ['32px', '24px'],
    maxWidth: [710, 325],
    padding: '0 1.5em',
  }),
)

const JoinUsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 20px',
})

const OrderButton = styled(ShopButton)(
  {
    textTransform: 'none',
  },
  mq({
    margin: '0 auto',
    width: ['auto', '100%'],
    marginTop: ['6rem', '2rem'],
    fontSize: ['3em', '2em'],
    padding: ['2px 100px 8px'],
  }),
)

export default OverviewSection
