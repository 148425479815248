import React, { FC } from 'react'

import { EconomicsSectionData } from '../../types'
import { fonts, fullWidth, mq, styled } from '../../styles'
import Title from '../Title'
import { withLineBreaks } from '../../lib/RenderUtils'
import FluidImage from '../base/FluidImage'
import Section from '../Section'

const EconomicsSection: FC<{
  section: EconomicsSectionData
}> = ({ section }) => {
  return (
    <Container>
      <Title>{withLineBreaks(section.section_title?.text || '')}</Title>
      <Image image={section.image} transparent />
      <ItemContainer>
        {section.items?.map((item, i) => {
          return (
            <Item key={i}>
              <ItemContent>
                <ItemText>{item?.text?.text}</ItemText>
              </ItemContent>
            </Item>
          )
        })}
      </ItemContainer>
    </Container>
  )
}

const Container = styled(Section)({
  alignItems: 'center',
})

const Image = styled(FluidImage)(
  {
    margin: '3em auto 5em',
  },
  mq({
    width: '100%',
    maxWidth: 1100,
  }),
)

const ItemContainer = styled('div')(
  {
    display: 'flex',
    maxWidth: 1000,
    margin: '0 auto',
    flexWrap: 'wrap',
  },
  fullWidth,
)

const Item = styled('div')(
  {
    padding: '0 10px',
  },
  mq({
    width: ['33%', 'auto'],
    marginBottom: [0, '1em'],
  }),
)

const ItemContent = styled('div')({})

const ItemText = styled('div')({
  textAlign: 'center',
  fontFamily: fonts.garamond.condensed,
  fontSize: '1.5em',
})

export default EconomicsSection
