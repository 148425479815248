import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from '../components/Layout'
import '../components/base.css'
import { HomeQuery } from '../../graphql-types'
import SectionList from '../components/SectionList'
import { withPreview } from 'gatsby-source-prismic'
import extractGlobals from '../lib/extractGlobals'

function RootIndex({ data }: PageProps<HomeQuery>) {
  const { meta, footer } = extractGlobals(data)
  return (
    <Layout tabNames={[]} meta={meta} footer={footer} showFinalPreorder={true}>
      <SectionList sections={data.prismicSplash?.data} />
    </Layout>
  )
}

export default withPreview(RootIndex, { mergeStrategy: 'traverseAndReplace' })

export const pageQuery = graphql`
  query Home {
    prismicSplash {
      _previewable
      data {
        ...Meta
        ...Footer
        ...SectionList
      }
    }
  }
`
