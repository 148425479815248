import React, { FC, useCallback } from 'react'

import { recordShopLinkClick } from '../lib/Analytics'
import useShopUrl from '../lib/useShopUrl'

type ShopLinkProps = JSX.IntrinsicElements['a'] & {
  location: string
}

const ShopLink = (props: ShopLinkProps) => {
  const shopUrl = useShopUrl()

  const handleClick = useCallback(() => {
    recordShopLinkClick(props.location)
  }, [props.location])

  return <a {...props} onMouseDown={handleClick} href={shopUrl}></a>
}

export default ShopLink
