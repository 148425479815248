import React, { FC } from 'react'

import { HeroMusicSectionData } from '../../types'
import BaseTitle from '../../components/Title'
import { colors, mq, styled } from '../../styles'
import { withLineBreaks } from '../../lib/RenderUtils'
import Section from '../Section'

const HeroMusicSection: FC<{
  section: HeroMusicSectionData
}> = ({ section }) => {
  return (
    <SectionContainer>
      <Container>
        {section.section_title?.text && (
          <Title key="title">
            {withLineBreaks(section.section_title.text)}
          </Title>
        )}
      </Container>
    </SectionContainer>
  )
}

const SectionContainer = styled(Section)(
  mq({
    backgroundColor: colors.mediumBrown,
    paddingBottom: [0, 0],
  }),
)

const Container = styled('div')(
  {
    backgroundRepeat: 'no-repeat',
    minHeight: '900px',
    backgroundPosition: 'bottom center',
    flexDirection: 'column',
  },
  mq({
    backgroundSize: ['auto 65%', '100% auto', '100% auto'],
    minHeight: ['auto'],
    paddingBottom: [0],
  }),
)

const Title = styled(BaseTitle)({})

export default HeroMusicSection
