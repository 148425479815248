import React, { FC } from 'react'

import { PrismicRawStructuredText } from '../types'
import { styled, fonts } from '../styles'
import { PrismicImageType } from '../../graphql-types'
import FluidImage from './base/FluidImage'

const TextBlock: FC<{
  title?: string
  content?: PrismicRawStructuredText[]
  image?: PrismicImageType
}> = ({ title, content, image }) => {
  return (
    <TextBlockContainer>
      {image && (
        <TextBlockImageContainer>
          <TextBlockImage image={image} />
        </TextBlockImageContainer>
      )}

      {title && <TextBlockTitle>{title}</TextBlockTitle>}
      {content && (
        <TextBlockText>
          {content.map((c, i) => {
            return <p key={i}>{c.text}</p>
          })}
        </TextBlockText>
      )}
    </TextBlockContainer>
  )
}

const TextBlockContainer = styled('div')({
  textAlign: 'center',
  margin: '0 2em',
})

const TextBlockImageContainer = styled('div')({
  padding: '0 1em',
})

const TextBlockImage = styled(FluidImage)({
  margin: '0 auto',
  marginBottom: '1.75em',
  maxWidth: '100%',
})

const TextBlockTitle = styled('div')({
  fontSize: '2.5em',
  lineHeight: 1,
})

const TextBlockText = styled('div')({
  fontFamily: fonts.garamond.condensed,
  fontSize: '1.5em',
})

export default TextBlock
