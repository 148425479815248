import React, { ReactElement } from 'react'

export const withLineBreaks = (text?: string | null) => {
  if (!text) return ''
  const lines = text.split('\n')
  const finalLines: (string | ReactElement)[] = [lines[0]]
  lines.slice(1).forEach((l, i) => {
    finalLines.push(<br key={i} />)
    finalLines.push(<span key={i + '-t'}>{l}</span>)
  })
  return finalLines
}
