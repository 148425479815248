import React, { FC } from 'react'

import { ProgrammingSectionData } from '../../types'
import { fonts, mq, styled } from '../../styles'
import TextBlock from '../TextBlock'
import Title from '../Title'
import { withLineBreaks } from '../../lib/RenderUtils'
import Section from '../Section'

const ProgrammingSection: FC<{
  section: ProgrammingSectionData
}> = ({ section }) => {
  return (
    <Container>
      <Title>{withLineBreaks(section.section_title?.text || '')}</Title>
      <ItemWrapper>
        <ItemContainer>
          {section.items?.slice(0, 2).map((i) => {
            return (
              <TextBlock
                title={i?.title?.text || undefined}
                content={i?.text?.raw || undefined}
              />
            )
          })}
        </ItemContainer>
        <ItemContainer>
          {section.items?.slice(2).map((i) => {
            return (
              <TextBlock
                title={i?.title?.text || undefined}
                content={i?.text?.raw || undefined}
              />
            )
          })}
        </ItemContainer>
      </ItemWrapper>
      <Title>{withLineBreaks(section.artists_list_title?.text || '')}</Title>
      <ArtistsContainer>
        {section.artists?.map((a) => {
          return (
            <ArtistItem>
              <ArtistItemInner>
                <ArtistInfo>{a?.description?.text}</ArtistInfo>
                <ArtistName>{a?.name?.text}</ArtistName>
              </ArtistItemInner>
            </ArtistItem>
          )
        })}
      </ArtistsContainer>
      <ArtistMoreText>And more...</ArtistMoreText>
    </Container>
  )
}

const Container = styled(Section)({})

const ItemWrapper = styled('div')({
  marginTop: '3em',
})

const ItemContainer = styled('div')(
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    maxWidth: 1000,
    margin: '0 auto',
  },
  mq({
    flexDirection: ['row', 'column'],
  }),
)

const ArtistsContainer = styled('div')({
  maxWidth: 1000,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  margin: '2em auto',
})

const ArtistItem = styled('div')({
  width: '33%',
  padding: '.75em',
  boxSizing: 'border-box',
})

const ArtistItemInner = styled('div')({
  borderRadius: '1em',
  textAlign: 'center',
  padding: '2em',
  border: '1px solid black',
})

const ArtistInfo = styled('div')({
  fontFamily: fonts.monosten.light,
  maxWidth: '60%',
  margin: '0 auto',
})

const ArtistName = styled('div')({
  fontSize: '2.5em',
})

const ArtistDescription = styled('div')({})

const ArtistMoreText = styled('div')({
  fontFamily: fonts.garamond.condensed,
  textAlign: 'center',
  fontSize: '3em',
})

export default ProgrammingSection
