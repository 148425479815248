import { styled } from '../styles'

const Subtitle = styled('div')({
  textAlign: 'center',
  fontSize: '1.75em',
  marginBottom: '3em',
  lineHeight: 1,
  position: 'relative',
  top: -8,
})

export default Subtitle
