import React, {
  FC,
  useCallback,
  useState,
  createRef,
  useLayoutEffect,
  useRef,
} from 'react'

import { SocialProofSectionData } from '../../Types'
import { mq, styled } from '../../styles'
import { fonts, colors } from '../../styles'
import Section from '../Section'
import {
  Maybe,
  SocialProofQuoteFragment,
  SocialProofSocialQuoteFragment,
} from '../../../graphql-types'
import FluidImage from '../base/FluidImage'
import Title from '../Title'
import Caption from '../Caption'

interface SectionSizeType {
  [key: number]: number
}

interface TextRefType {
  [key: number]: HTMLDivElement
}

const SocialProofSection: FC<{
  section: SocialProofSectionData
}> = ({ section }) => {
  const containerRef = createRef<HTMLDivElement>()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [sectionSizes, setSectionSizes] = useState<SectionSizeType>(
    section.quotes ? { ...section.quotes.map((q) => 4) } : {},
  )
  const textRefs = useRef<TextRefType>({})

  useLayoutEffect(() => {
    let changed = false
    let newSizes = { ...sectionSizes }
    // key: index, value: HTMLDivElement
    for (const [key, value] of Object.entries(textRefs.current)) {
      if (!value) continue
      const keyNumber = (key as unknown) as number
      const height = value.getBoundingClientRect().height
      let nextSize = sectionSizes[keyNumber] || 0
      if (height > 140 && nextSize > 1) {
        changed = true
        nextSize -= 0.1
      }
      newSizes[keyNumber] = nextSize
    }
    if (changed) {
      setSectionSizes(newSizes)
    }
  })

  const handleClickLogo = useCallback((index) => {
    setCurrentIndex(index)
  }, [])

  const updateTextRef = useCallback((el: HTMLDivElement, index: number) => {
    textRefs.current[index] = el
  }, [])

  return (
    <>
      <Container withoutTitle={true}>
        <Quotes ref={containerRef}>
          <Quote
            index={currentIndex}
            fontSize={sectionSizes[currentIndex]}
            data={section.quotes?.[currentIndex]}
            textRef={updateTextRef}
          />
        </Quotes>
        <QuoteLogoContent>
          {section.quotes?.map((q, quoteIndex) => {
            return (
              <Logo
                key={quoteIndex}
                index={quoteIndex}
                data={q}
                currentIndex={currentIndex}
                onClick={handleClickLogo}
              />
            )
          })}
        </QuoteLogoContent>
      </Container>

      <SocialQuoteSection withoutTitle={true}>
        {/* <SocialQuoteTitle>{section.social_quotes_title?.text}</SocialQuoteTitle> */}
        <SocialQuoteContent>
          {section.social_quotes?.map((q, quoteIndex) => {
            return <SocialQuote key={quoteIndex} data={q} />
          })}
        </SocialQuoteContent>
        <SocialQuoteCaption>
          {section.social_quotes_title?.text}
        </SocialQuoteCaption>
      </SocialQuoteSection>
    </>
  )
}

const Logo: FC<{
  index: number
  currentIndex: number
  onClick: (index: number) => void
  data?: Maybe<SocialProofQuoteFragment>
}> = (props) => {
  const handleClickLogo = useCallback(() => {
    props.onClick(props.index)
  }, [props.index])

  return (
    <LogoButton
      selected={props.currentIndex === props.index}
      onClick={handleClickLogo}>
      <LogoImage
        transparent={true}
        image={props.data?.logo}
        aspectRatio={props.data?.logo?.fluid?.aspectRatio}
      />
    </LogoButton>
  )
}

const Quote: FC<{
  index: number
  fontSize: number
  data?: Maybe<SocialProofQuoteFragment>
  textRef: (el: HTMLDivElement, index: number) => void
}> = ({ fontSize, textRef, index, data }) => {
  const updateRef = useCallback(
    (el: HTMLDivElement) => {
      textRef(el, index)
    },
    [index],
  )

  return (
    <QuoteContainer>
      <QuoteContent>
        <QuoteText ref={updateRef} style={{ fontSize: `${fontSize}em` }}>
          {data?.quote?.raw?.map((p, i) => {
            const isFirst = i === 0
            const isLast = i === data?.quote?.raw?.length - 1
            return (
              <p key={i}>
                {isFirst ? <StartQuote>“</StartQuote> : ''}
                {p.text}
                {isLast ? <EndQuote>”</EndQuote> : ''}
              </p>
            )
          })}
        </QuoteText>
        <QuoteAuthorName
          href={data?.link && data.link.url ? data.link.url : '/'}
          target="_blank">
          {`– ${data?.author}`}
        </QuoteAuthorName>
      </QuoteContent>
    </QuoteContainer>
  )
}

const SocialQuote: FC<{
  data?: Maybe<SocialProofSocialQuoteFragment>
}> = (props) => {
  return (
    <SocialQuoteContainer>
      <SocialQuoteText>
        {props.data?.quote?.raw?.map((p, i) => {
          const isFirst = i === 0
          const isLast = i === props.data?.quote?.raw?.length - 1
          return (
            <p key={i}>
              {isFirst ? <StartQuote>“</StartQuote> : ''}
              {p.text}
              {isLast ? <EndQuote>”</EndQuote> : ''}
            </p>
          )
        })}
      </SocialQuoteText>
      <SocialQuoteAuthorContainer>
        <IconImage
          transparent={true}
          image={props.data?.icon}
          imageStyle={{ borderRadius: 30 }}
        />
        <SocialHandleContainer>
          <SocialQuoteAuthorName>{props.data?.author}</SocialQuoteAuthorName>
          {props.data?.link && props.data.link.url && (
            <SocialQuoteHandle href={props.data.link.url} target="_blank">
              {props.data?.social_handle}
            </SocialQuoteHandle>
          )}
        </SocialHandleContainer>
      </SocialQuoteAuthorContainer>
    </SocialQuoteContainer>
  )
}

const Container = styled(Section)(
  mq({
    width: '100vw',
    overflow: 'hidden',
    display: 'block',
    padding: [0, 0],
    position: 'relative',
    backgroundColor: colors.mediumBrown,
  }),
).withComponent('ul')

const Quotes = styled('div')(
  mq({
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100vw',
  }),
)

const QuoteContainer = styled('li')({
  width: '100vw',
  padding: 0,
  listStyle: 'none',
  fontWeight: 'lighter',
  overflow: 'hidden',
  ['p']: {
    margin: 0,
  },
})

const QuoteContent = styled('div')(
  mq({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 800,
    margin: '0 auto',
    padding: ['5em 2em 0em', '3em 1em 0em'],
    height: '100%',
    minHeight: [240, 220],
    justifyContent: 'flex-end',
  }),
)

const QuoteText = styled('div')(
  {
    position: 'relative',
    lineHeight: 1,
    textAlign: 'center',
    textDecoration: 'none',
  },
  mq({
    fontSize: ['4em', '3em'],
  }),
)

const QuoteAuthorName = styled('a')(
  {
    fontFamily: fonts.monosten.light,
    lineHeight: 1,
    textAlign: 'center',
    textDecoration: 'none',
    color: colors.goldenBrown,
    marginTop: 10,
  },
  mq({
    fontSize: ['0.9em', '0.9em'],
  }),
)

const StartQuote = styled('span')({
  position: 'relative',
  left: '-.4em',
  display: 'inline-block',
  width: 0,
})

const EndQuote = styled('span')({
  position: 'relative',
  display: 'inline-block',
  width: 0,
})

const QuoteLogoContent = styled('div')(
  mq({
    display: 'flex',
    flexDirection: 'row-wrap',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    maxWidth: 800,
    margin: '0 auto',
    padding: ['2.25em 2em 6em', '1em 1em 3em'],
    height: '100%',
    justifyContent: 'center',
  }),
)

const LogoButton = styled.button<{ selected: boolean }>(
  {
    border: 0,
    boxSizing: 'content-box',
    outline: 0,
    cursor: 'pointer',
    userSelect: 'none',
    margin: '0 20px',
    minHeight: 40,
  },
  (props) => ({
    opacity: props.selected ? 1 : 0.3,
    borderBottom: props.selected ? 'black 1px solid' : 'none',
  }),
)

const LogoImage = styled(FluidImage)<{ aspectRatio: number }>(
  {
    minWidth: 100,
    height: 'auto',
  },
  (props) => ({
    width: `calc(21px * ${props.aspectRatio})`,
  }),
)

const SocialQuoteSection = styled(Section)(
  mq({
    // borderTop: [0, 0],
    // paddingTop: [0, 0],
  }),
)

const SocialQuoteTitle = styled.div(
  {
    fontFamily: fonts.garamond.condensed,
    textAlign: 'center',
  },
  mq({
    fontSize: ['4em', '2em'],
    // maxWidth: [350],
    margin: '0 auto',
    lineHeight: 0.9,
  }),
)
// const SocialQuoteTitle = styled(Title)({
//   // marginBottom: '.5em',
// })

const SocialQuoteContent = styled('div')(
  mq({
    display: 'flex',
    flexDirection: ['row', 'column'],
    alignItems: 'center',
    minWidth: [980, 300],
    height: '100%',
    justifyContent: 'space-between',
    // paddingTop: '2em',
  }),
)

const SocialQuoteContainer = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 310,
    padding: '1em',
    ['p']: {
      margin: 0,
    },
    border: 'black 1px solid',
    borderRadius: 20,
  },
  mq({
    height: [260, 240],
    marginBottom: [0, 15],
  }),
)

const SocialQuoteText = styled('div')(
  {
    fontFamily: fonts.garamond.condensed,
    fontWeight: 'lighter',
    lineHeight: 1,
    WebkitFontSmoothing: 'antialiased',
    padding: '0.1em 0.2em 0em',
  },
  mq({
    fontSize: ['2.3em', '2em'],
  }),
)

const SocialQuoteAuthorContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})

const SocialHandleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

const SocialQuoteAuthorName = styled.div(
  mq({
    fontSize: ['1.2em', '1em'],
  }),
)

const SocialQuoteHandle = styled.a(
  {
    fontFamily: fonts.monosten.light,
    color: colors.goldenBrown,
    textDecoration: 'none',
  },
  mq({
    fontSize: ['.9em', '0.9em'],
  }),
)

const SocialQuoteCaption = styled(Caption)(
  mq({
    marginTop: ['2em'],
  }),
)

const IconImage = styled(FluidImage)({
  width: 50,
  marginRight: 10,
})

export default SocialProofSection
