import React, { FC } from 'react'

import { SpeakerDesignSectionData } from '../../types'
import { fonts, mq, styled, widthToFill } from '../../styles'
import Title from '../Title'
import { withLineBreaks } from '../../lib/RenderUtils'
import FluidImage from '../base/FluidImage'
import Section from '../Section'
import P from '../base/P'
import ShopLink from '../ShopLink'
import BaseSubtitle from '../Subtitle'

const SpeakerDesignSection: FC<{
  section: SpeakerDesignSectionData
}> = ({ section }) => {
  return (
    <Container>
      <Title>{withLineBreaks(section.section_title?.text || '')}</Title>
      <Subtitle>{section.subtitle?.text}</Subtitle>

      <ImageLink location={'speaker-design-image'}>
        <Image transparent image={section.image} />
      </ImageLink>

      <DescriptionText>
        {section.text?.raw?.map((t, i) => {
          return t.type === 'heading2' ? (
            <DescriptionTextSection key={i}>{t.text}</DescriptionTextSection>
          ) : (
            <P key={i}>{t.text}</P>
          )
        })}
      </DescriptionText>

      {section.secondary_text?.text && (
        <SecondaryText>{section.secondary_text?.text}</SecondaryText>
      )}
    </Container>
  )
}

const Container = styled(Section)({})

const Subtitle = styled(BaseSubtitle)(
  {},
  mq({
    top: [-7, 0],
    marginTop: [0, '.2em'],
    marginBottom: ['0'],
  }),
)

const ImageLink = styled(ShopLink)(
  {
    display: 'block',
    margin: '0 auto',
    marginTop: '6em',
    maxWidth: '100%',
  },
  mq({
    width: [1200, '100%'],
    marginTop: ['6em', '3em'],
  }),
)

const Image = styled(FluidImage)({
  maxWidth: '100%',
})

const DescriptionText = styled('div')(
  {
    margin: '0 auto',
    textAlign: 'center',
    marginTop: '1.25em',
    marginBottom: '-.8em',
  },
  widthToFill(600),
)

const DescriptionTextSection = styled('div')(
  {
    fontFamily: fonts.garamond.condensed,
    fontSize: '2em',
    lineHeight: 1.15,
    margin: '0 auto',
    marginTop: '.7em',
    marginBottom: '.5em',
    ':last-child': {
      marginBottom: 0,
    },
  },
  widthToFill(525),
)

const SecondaryText = styled(P)(
  {
    fontFamily: fonts.monosten.light,
    margin: '0 auto',
    marginTop: '3em',
  },
  widthToFill(350),
)

export default SpeakerDesignSection
